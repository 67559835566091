import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import amplitude from 'amplitude-js';
import {environment} from '../../environments/environment';
import {DashboardService} from './dashboard.service';
import {DASHBOARD_MENU} from '../shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

export enum AMPLITUDE_EVENT_CATEGORIES {
    Onboarding = 'Onboarding',
    Engagement = 'Engagement',
    Subscription = 'Subscription',
    Usage = 'Usage',
    Error = 'Error',
    Expansion = 'Expansion',
    TopOfFunnel = 'TopOfFunnel',
}

export enum AMPLITUDE_EVENTS {
    SELECT_CARD_TEMPLATE = 'select card template',
    SETUP_CARD = 'setup card',
    CUSTOMIZE_CARD = 'customize card design',
    LAUNCH_CARD = 'launch card',
    DOWNLOAD_CARD_QR_CODE = 'download card qr code',
    BULK_CREATE_CARD = 'bulk create card',
    DOWNLOAD_WALLET_PASS = 'download wallet pass',
    BULK_INVITE_USERS = 'bulk invite users',
    TRIAL_START = 'start trial',
    QR_FILTERS_COMBINATION = 'QR Filters Combination',
    QR_FILTERS = 'QR Filters',
    QR_FILTER_NO_RESULTS = 'QR Filter No Results',
    QR_VIEW = 'QR Listing View',
    SETUP_TEMPLATE_CARD = 'setup template card',
    CUSTOMIZE_TEMPLATE_CARD = 'customize template card',
    LAUNCH_TEMPLATE_CARD = 'launch template card',
    SELECT_NEW_CARD_TEMPLATE = 'select new card template',
    SHARE_CARD = 'share card',
    EXPORT_QR_CODES = 'Export QR Codes',
    SEARCH_SEE_MORE = 'GlobalSearch_Seemore',
    SEARCH_SEE_ALL_RELATED_ARTCILES = 'GlobalSearch_Helpdocs',
    SEARCH_ON_FILTERS = 'GlobalSearch_Filters',
    SEARCH_ON_SORTS = 'GlobalSearch_Sort',
    SEARCH_CLICKS_ON_SEARCH_ICON = 'GlobalSearch_Clicks',
    QR_COPY_TO_CLIPBOARD = 'QR_Finalstep_CopytoClipboard',
    TOOLBAR_OPTIONS = 'toolbar options',
    DBC_LIST_FILTER = 'dbc list filter',
    EXPORT_CONTACTS = 'export contacts',
    ANALYTICS_PAGE_SCROLL_DBC = 'analytics page scrolled for dbc',
    SETTING_CUSTOM_FONTS = 'setting-custom-fonts',
    QR_PAGE_VIEW_ANALYTICS_CTA = 'QRpage_ViewAnalytics_CTA',
    ANALYTICS_FILTERS_QR = 'Analytics Filters QR',
    ANALYTICS_FILTERS_DBC = 'Analytics Filters DBC',
    LINKPAGE_DUPLICATE = 'Duplicate_Linkpage',
    LINKPAGE_TRANSFER = 'Org_transfer_linkpage',
    LINKPAGE_COPY = 'Org_Copy_linkpage',
    DIRECT_PREVIEW = 'direct_preview',
    DBC_WEEKLY_ANALYTICS = 'dbc-weekly-analytics',
    ANALYTICS_SHARE = 'Analytics_Share',
    ANALYTICS_SENT = 'Analytics_Sent',
    ANALYTICS_CONTRIBUTING_QR = 'Analytics_ViewQR_Clicks',
    ANALYTICS_EXPORT_CLICKS = 'Analytics_Export_Clicks',
    ANALYTICS_TOP_QR_EXPORT = 'Analytics_TopQRschart_Export',
    ANALYTICS_TOP_CAMPAIGN_EXPORT = 'Analytics_ScansbyQRType_Export',
    QR_VIEW_ANALYTICS = 'view analytics',
    ADD_CUSTOM_DOMAIN_CTA = 'AddCustomDomain_CTA',
    AUTOMATIC_QR_SAVED = 'AutomaticQR_saved',
    AUTOMATIC_QR_CLICKS = 'AutomaticQR_clicks',
    ADDONS = 'addons',
    CANCELLATION_CLAIMED_OFFER = 'Cancellation_claimedoffer',
    FOMO_KEEP_SUBSCRIPTION_CLICKS = 'FOMO_KeepSubscription_clicks',
    CANCEL_SUBSCRIPTION_CLICKS = 'CancelSubscription_clicks',
    DOWNLOAD_QR_CODE_OR_COPY_TO_CLIPBOARD = 'Download_Qr_Code_Or_Copy_To_Clipboard',
    CONTACT_SALES = 'Contact sales',
    CANCELLATION_INITIATED_DBC = 'cancellation_initiated_dbc',
    CANCELLATION_FEEDBACK_DBC = 'cancellation_feedback_submitted_dbc',
    CANCEL_CLAIMEDOFFER_DBC = 'cancel_claimedoffer_dbc',
    CANCELLED_SUBSCRIPTION_DBC = 'cancelled_subscription_dbc',
    VIEW_DASHBOARD = 'view_dashboard',
    ADVANCED_CUSTOMIZATION = 'adv_customization_upsell_modal_opened',
    PASSWORD_PROTECTION = 'Password_protect_upsell_CTA',
    AGE_RESTRICTION = 'Age_restrict_upsell_CTA',
    PASSWORD_PROTECTION_USAGE = 'password_protection_click',
    AGE_RESTRICTION_USAGE = 'age_restriction_click',
    LOCATION_TRACKING_USAGE = 'Toggle_GPS_tracking',
    QR_TEMPLATE_LIST = 'qr_templates_listing_view',
    QR_TEMPLATE_CREATE = 'create_QR_template_CTA',
    LANDING_PAGE_LIST = 'landingpage_listing_view',
    CREATE_LANDING_PAGE = 'create_landingpage_CTA',
    EDIT_LANDING_PAGE = 'edit_landingpage_CTA',
    SAVE_LANDING_PAGE = 'save_landingpage_CTA',
    SELECT_LANDING_PAGE_TEMPLATE = 'select_landingpage_template',
    QR_NOTES = 'QR_Notes',
    CREATE_QR_CODE_SIDENAV_CLICKS= 'create_qr_code_sidenav_clicks',
    CREATE_QR_PRIMARY_CTA_CLICKS = 'CreateQR_PrimaryCTA_clicks',
    CREATE_ALL_CLICKS = 'Create_All_clicks',

    // Overview page events
    OVERVIEW_CARD_CLICKS = 'Overview_card_clicks',
    OVERVIEW_CREATE = 'Overview_Create',
    OVERVIEW_NAV_CLICKS = 'Overview_nav_clicks',

    INVITE_TEAMMATES_CLICKS = 'InviteTeammates_Clicks',
    INVITE_TEAMMATES_UPGRADES = 'InviteTeammates_Upgrades',
    ANALYTICS_BANNER_CTA = 'AnalyticsBanner_CTA',
    CSV_EXPORT_UPGRADE = 'CSVExport_Upgrade',
    CHART_UPGRADE = 'Chart_Upgrade',
    DATEPICKER_UPGRADE = 'Datepicker_Upgrade',
    KNOW_MORE_DISCOUNT = 'spu_banner_click',
    SPU_BANNER_CLOSE = 'spu_banner_close',

    // QR watchlist events
    WATCHLIST_TAB_CLICK = 'Watchlist_tab_clicks',
    ADD_TO_WATCHLIST_CLICK = 'AddtoWatchlist_Clicks',
    REMOVE_FROM_WATCHLIST = 'Remove_from_Watchlist',
    ADD_TO_WATCHLIST = 'Watchlist_QRCount',
    REMOVE_FROM_WATCHLIST_CLICK = 'RemoveWatchlist_Clicks',

    LINKPAGE_AD_MODAL_CLICK = 'Linkpage_Ad_Modal_click',
    LINKPAGE_AD_MODAL_VIEW = 'Linkpage_Ad_Modal_View',
    EXPLORE_QR_CODE_TYPES = 'Explore QR Code Types',
    MYCARDS_LANDING_PAGE_OPENED_DESKTOP_POPUP = 'mycards_landing_page_opened_desktop_popup',
    MYCARDS_NUDGE_CLICKED_MOBILE = 'mycards_nudge_clicked_mobile',
    QR_CODES_IN_DROPDOWN_OF_SIDENAV = 'qr codes in dropdown of sidenav',
    UNIQODE_LOGO_TOP_NAV = 'uniqode logo top nav',
    CREATE_QR_CODE_CTA_LISTING = 'create qr code cta listing',
    CREATE_QR_CODE_CTA_CENTRE_LISTING = 'create qr code cta centre listing',
    BUY_NOW_PRICING_CTA =  'Buy now pricing cta',
    LINKPAGE_VIEW_ANALYTICS = 'Linkpage_view_analytics',

    // Form events
    FORMS_ADD_QUESTION = 'forms_add_question',
    FORMS_SELECT_THEME = 'forms select theme',
    FORMS_ENABLE_SELF_NOTIFICATION = 'forms enable self notification',
    FORMS_ENABLE_EMAIL_NOTIFICATION = 'forms enable email notification',
    FORMS_ENABLE_RETARGETING = 'forms enable retargeting',
    FORMS_SELECT_LANGUAGE = 'forms select language',
    FORMS_CREATED = 'forms created',
    FORMS_RESPONSES_VIEW = 'forms_responses_view',
    FORMS_SIDEBAR_CLICKS = 'forms_sidebar_clicks',
    FORMS_SHARED = 'forms_shared',

    FORM_TRANSFER = 'Org_transfer_forms',
    FORM_COPY = 'Org_Copy_forms',

    SELECT_FORM_TEMPLATE = 'Select form template',

    FORMS_PAGE_VIEWED = 'forms_page_viewed',
    CREATE_FORM_CTA = 'forms_create_cta',
    FORMS_EMAIL_CTR = 'forms_email_CTR',

    FORMS_VIEW_RESPONSES = 'forms_view_responses',
    FORMS_EXPORT_RESPONSES = 'forms_export_responses',

    FORMS_CUSTOMIZED = 'forms_customized',
    QR_TEMPLATE_CLICKS = 'QR Template Clicks',
    /* DBC Email Signature amplitude events */
    EMAIL_SIGN_MODAL_OPENED = 'email_sign_modal_opened',
    EMAIL_SIGN_COPIED = 'email_sign_copied',
    EMAIL_SIGN_DOWNLOADED = 'email_sign_downloaded',
    FINISH_QR = 'finish_qr',

    /* DBC virtual background amplitude events */
    VIRTUAL_BACKGROUND_MODAL_OPENED = 'virtual_bg_modal_opened',
    VIRTUAL_BACKGROUND_DOWNLOADED = 'virtual_bg_downloaded',

    /* DBC pricing page buy now cta */
    DBC_SCHEDULE_DEMO = 'dbc_schedule_demo',
    DBC_BUY_NOW = 'dbc_buy_now',
    PRODUCT_SWITCHER = 'product_switcher',

    /* QR AI Analytics */
    QR_AI_ANALYTICS_CLICKS = 'QR_AI_Analytics_clicks',
    QR_AI_PROMPT_CLICKS = 'QR_AI_prompt_clicks',
    QR_AI_FEEDBACK = 'QR_AI_feedback',
    QR_AI_RESPONSE_COPIED = 'QR_AI_response_copied',
    QR_ANALYTICS_AI_CONSENT_PROVIDED = 'QR_Analytics_AI_ConsentProvided',
    QR_ANALYTICS_AI_CONSENT_REQUESTED = 'QR_Analytics_AI_ConsentRequested',

    /* Collapsible Side Nav Toggle */
    COLLAPSIBLE_SIDE_NAV_TOGGLE = 'collapsible_side_nav_toggle',

    /* DBC Onboarding events Experiment 3 */
    COMPANY_DETAILS_SCREEN_VIEWED = 'company_details_screen_viewed',
    COMPANY_DETAILS_EDIT = 'company_details_edit',
    PHONE_NUMBER_SCREEN_VIEWED = 'phone_number_screen_viewed',
    PHONE_NUMBER_EDIT = 'phone_number_edit',
    UPLOAD_PHOTO_SCREEN_VIEWED = 'upload_photo_screen_viewed',
    PHOTO_UPLOADED = 'photo_uploaded',
    CARD_DESIGN_SCREEN_VIEWED = 'card_design_screen_viewed',
    ONBOARD_COMPLETED = 'onboard_completed',
    CARD_EDITING_CONTINUED = 'card_editing_continued',

    /* NEW DBC EVENTS */
    PAGE_VIEWED = 'page_viewed',
    CARD_DESIGN_SELECTED = 'card_design_selected',
    CARD_PHOTO_EDIT = 'card_photo_edit',
    CARD_PHOTO_REMOVE = 'card_photo_remove',
    CARD_PHOTO_UPLOAD = 'card_photo_upload',
    CARD_PHOTO_SAVED = 'card_photo_saved',
    CARD_PHOTO_CHANGE = 'card_photo_change',
    CARD_DETAILS_CUSTOMIZED = 'card_details_customized',
    CARD_DESIGN_CUSTOMIZED = 'card_design_customized',
    CARD_SET_UP_CUSTOMIZED = 'card-set-up_customized',
    CARD_CREATED = 'card_created',
    CARD_SHARED = 'card_shared',
    MOBILE_APP_GET = 'mobile_app_get',
    LEADS_EXPORTED = 'leads_exported',

    /* DBC REMOVE UNIQODE BRANDING FOOTER*/
    REMOVE_BRANDING_MODAL_VIEWED = 'remove_branding_modal_viewed',
    BULK_CREATE_MODAL_VIEWED = 'bulk_create_modal_viewed',
    AUTO_DESIGN_MODAL_VIEWED = 'auto_design_modal_viewed',
    REMOVE_UNIQODE_LOGO_MODAL_VIEWED = 'remove_uniqode_logo_modal_viewed',


    /* DBC FOLLOW-UP EMAIL EVENTS */
    FOLLOW_UP_EMAIL_TOGGLE = 'follow_up_email_toggle',
    FOLLOW_UP_EMAIL_AUTHORIZE_WITH_GOOGLE_CLICKED = 'follow_up_email_authorize_with_google_clicked',
    FOLLOW_UP_EMAIL_CONTENT_UPDATED = 'follow_up_email_content_updated',
    FOLLOW_UP_EMAIL_TIME_DELAY_UPDATED = 'follow_up_email_time_delay_updated',
    FOLLOW_UP_EMAIL_TEST_EMAIL_CLICKED = 'follow_up_email_test_email_clicked',


    // GS1 Events
    GS1_CODES_SAVED = 'gs1_codes_saved',
    GS1_CODES_AI_TYPE = 'gs1_codes_ai_type',
    CREATE_GS1_CTA_CLICKS = 'Create_GS1_CTA_clicks',
    CREATE_GS1_NEXT = 'Create_GS1_Next',
    CREATE_GS1_COMPLETE = 'Create_GS1_Complete',
    /* DBC NEW ONBOARDING EVENTS */
    CHOOSE_PRODUCT = 'choose_product',
    CARD_CREATION_METHOD_SELECTED = 'card_creation_method_selected',
    BUILD_YOUR_CARD = 'build_your_card',
    SAVE_MY_CARD = 'save_my_card',
    SCAN_FROM_PHOTO = 'scan_from_photo',
    PHOTO_IMAGE_PROCESSED = 'photo_image_processed',
    SIGNUP_SUBMITTED = 'signup_submitted'
}

@Injectable({
    providedIn: 'root'
})
export class AmplitudeService {
    hasDBCAccess = false;
    hasQRAccess = false;
    private isInitialized$ = new BehaviorSubject<boolean>(false);
    private eventQueue: Array<{category: AMPLITUDE_EVENT_CATEGORIES, event: string, properties: object}> = [];
    private maxRetries = 3;

    constructor(
        private authService: AuthService,
        private dashboardService: DashboardService
    ) {
        this.initializeAmplitude();
    }

    private initializeAmplitude(): void {

        this.authService.isLoggedIn$.subscribe(loggedIn => {
            if (loggedIn) {
                this.hasDBCAccess = this.authService.getUser()?.hasDBCAccess(this.authService.getCurrentOrgId()) || false;
                this.hasQRAccess = this.authService.getUser()?.hasQRAccess(this.authService.getCurrentOrgId()) || false;

                this.dashboardService.currentDashboard$.subscribe((dashboard) => {
                    this.initializeAmplitudeInstance(dashboard);
                });
            } else {
                this.resetAmplitude();
            }
        });
    }

    private initializeAmplitudeInstance(dashboard: string): void {
        try {
            const userId = this.authService.getUser()?.id?.toString();
            let apiKey: string = null;

            if (dashboard === DASHBOARD_MENU.QRCODES && this.hasQRAccess) {
                apiKey = environment.amplitudeAPIKey;
            } else if (dashboard === DASHBOARD_MENU.CARDS && this.hasDBCAccess) {
                apiKey = environment.amplitudeCardsAPIKey;
            } else {
                // For any other dashboard, initialize with QR API key if has access, otherwise DBC
                apiKey = this.hasQRAccess ? environment.amplitudeAPIKey :
                    (this.hasDBCAccess ? environment.amplitudeCardsAPIKey : null);
            }

            if (apiKey) {
                this.destroyAnonymousTracking();
                this.initializeWithKey(apiKey, userId);
            }
        } catch (error) {
            console.error('Failed to initialize Amplitude', { error, dashboard });
            this.isInitialized$.next(false);
        }
    }

    initializeAnonymousTracking(anonymousId: string): void {
        try {
            amplitude.getInstance().init(environment.amplitudeCardsAPIKey, anonymousId);
            this.isInitialized$.next(true);
        } catch (error) {
            console.error('Failed to initialize anonymous Amplitude tracking', { error });
            this.isInitialized$.next(false);
        }
    }

    destroyAnonymousTracking(): void {
        try {
            const instance = amplitude.getInstance();
            if (instance) {
                instance.setUserId(null);
                instance.regenerateDeviceId();
                this.isInitialized$.next(false);
            }
        } catch (error) {
            console.error('Failed to destroy anonymous Amplitude tracking', { error });
        }
    }

    private initializeWithKey(apiKey: string, userId: string): void {
        amplitude.getInstance().init(apiKey, userId);
        this.setUserJoiningDate();
        this.isInitialized$.next(true);
        this.processEventQueue();
    }

    private resetAmplitude(): void {
        try {
            amplitude.getInstance().setUserId(null);
            amplitude.getInstance().regenerateDeviceId();
            this.isInitialized$.next(false);
        } catch (error) {
            console.error('Failed to reset Amplitude', { error });
        }
    }

    private setUserJoiningDate(): void {
        try {
            const identity = new amplitude.Identify();
            const user = this.authService.getUser();
            if (user && user.date_joined) {
                identity.setOnce('created_at', user.date_joined.toISOString());
                amplitude.getInstance().identify(identity);
            }
        } catch (error) {
            console.error('Failed to set user joining date in Amplitude', { error });
        }
    }

    private processEventQueue(): void {
        if (this.eventQueue.length > 0) {
            console.log(`Processing ${this.eventQueue.length} queued Amplitude events`);

            // Create a copy of the queue and clear the original
            const queueToProcess = [...this.eventQueue];
            this.eventQueue = [];

            // Process each event
            queueToProcess.forEach(item => {
                this.sendEventToAmplitude(item.category, item.event, item.properties);
            });
        }
    }

    /**
     * @param category AMPLITUDE_EVENT_CATEGORIES
     * @param event Name of the event (preferably from AMPLITUDE_EVENTS enum)
     * @param eventProperties JSON object with properties to be tracked
     * @param retryCount Internal parameter for tracking retries
     */
    logEvent(
        category: AMPLITUDE_EVENT_CATEGORIES,
        event: string,
        eventProperties: object = null,
        retryCount: number = 0
    ): void {
        if (!category) {
            console.error('Amplitude event category is required', { event });
            throw new Error('Amplitude event category is required');
        }

        if (!event) {
            console.error('Amplitude event name is required', { category });
            throw new Error('Amplitude event name is required');
        }

        // Check if Amplitude is initialized
        if (!this.isInitialized$.getValue()) {
            // Queue the event for later processing
            this.eventQueue.push({
                category,
                event,
                properties: eventProperties || {}
            });

            // If this is not already a retry, attempt to initialize if possible
            if (retryCount === 0) {
                this.waitForInitialization().subscribe(() => {
                    this.processEventQueue();
                });
            }
            return;
        }

        this.sendEventToAmplitude(category, event, eventProperties || {}, retryCount);
    }

    private sendEventToAmplitude(
        category: AMPLITUDE_EVENT_CATEGORIES,
        event: string,
        properties: object,
        retryCount: number = 0
    ): void {
        try {
            amplitude.getInstance().logEvent(event, properties);
        } catch (error) {
            console.error('Failed to log Amplitude event', { category, event, properties, error });

            // Retry logic for failed events
            if (retryCount < this.maxRetries) {
                setTimeout(() => {
                    this.logEvent(category, event, properties, retryCount + 1);
                }, Math.pow(2, retryCount) * 1000); // Exponential backoff
            }
        }
    }

    /**
     * Wait for Amplitude to be initialized before proceeding
     */
    private waitForInitialization(): Observable<boolean> {
        return this.isInitialized$.pipe(
            filter(initialized => initialized),
            take(1)
        );
    }

    /**
     * @param properties JSON object with properties to be set on the user profile
     */
    setUserProperties(properties: object): void {
        if (!properties) {
            return;
        }

        this.waitForInitialization().subscribe(() => {
            try {
                const identity = new amplitude.Identify();
                for (const property in properties) {
                    identity.set(property, properties[property]);
                }
                amplitude.getInstance().identify(identity);
            } catch (error) {
                console.error('Failed to set Amplitude user properties', { properties, error });
            }
        });
    }

}
